@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

body, html {
    overflow-x: hidden;
    height: 100%;
    margin: 0;
}

#root {
    height: 100%;
}

.form-group {
    @apply mb-0;
}

label.control-label {
    @apply text-gray-500 text-sm font-medium block mb-1;
}

.field-description {
    @apply text-gray-500 text-xs mb-1;
}

input[type="text"].form-control,
input[type="password"].form-control  {
    @apply bg-gray-200 bg-opacity-60 border-2 border-transparent text-gray-500 rounded-lg text-sm px-3 py-2 mb-4 w-full outline-none focus:border-gray-200;
}

button[type="submit"].btn {
    @apply bg-brand-teal text-white rounded-lg text-center text-sm font-medium px-5 py-2 hover:opacity-80;
}

.edit-icon {
    @apply w-6 h-6 absolute top-0 right-0 mr-4 mt-6 text-edit-grey;
}

.edit-fields-titles {
    @apply text-edit-grey font-semibold text-sm
}

.commercials-nav-tab {
    @apply text-center pt-3 hover:shadow-inner hover:border-x-[1.5px] hover:border-t-[1.5px]
}

.edit-fields {
    @apply border-[1.5px] w-full h-7 rounded-full outline-none px-4 py-0.5 text-edit-grey text-sm font-semibold
}

.login-fields {
    @apply border-[1.5px] w-[90%] rounded-full h-[1.5rem] focus:outline-none focus:bg-clarity text-sm text-center
}

input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
}

.spinner {
    width: 40px;
    height: 40px;

    position: relative;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #b7b7b7;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.dashboard-pie {
    --v:calc( ((18/5) * var(--p) - 90)*1deg);
    
    border-radius:50%;
    background:
        linear-gradient(var(--v), #33a8b8 50%,transparent 0) 0 /calc((1 - var(--s))*100%),
        linear-gradient(var(--v), transparent 50%,#f18783 0) 0 /calc(var(--s)*100%),

        linear-gradient(to right, #33a8b8 50%,#f18783 0);
}

.change {
    rotate: -180deg;
    transition-duration: 0.3s
}

.pieNormal {
    rotate: 0deg;
    transition-duration: 0.3s
}

.linear-pie {
    background: linear-gradient(#f18783, #33a8b8);
}

.filter-dropdown {
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-out;  /* adjust as needed */
}

.filter-dropdown.open {
    height: 4rem;  /* adjust as needed */
}

.dashboard-nav {
    transition: transform 0.3s ease-out;
    transform: translateX(0);
}

.dashboard-exit {
    transform: translateX(-180%);
    transition: transform 0.3s ease-in;
}

.dashboard-popup {
    transform: translateX(0);
    transition: transform 0.3s ease-in;
}

.hamburger {
    transition-delay: 2s;
    display: block;
}

.hamburger-exit {
    display: none;
    transition-delay: 2s;
    transition-timing-function: ease-in-out;
    transition-property: display;
}

.managerbuildingFirst {
    animation: slide-right-enter 0.5s ease-out forwards;
}

.managerbuildingSecond {
    animation: slide-left-enter 0.5s ease-out forwards;
}

.property-grid {
    display: inline-grid;
    grid-template-columns: auto auto
}

@keyframes slide-right-exit {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50%);
        opacity: 0;
    }
}

@keyframes slide-left-exit {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
        opacity: 0;
    }
}

@keyframes slide-right-enter {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-left-enter {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
    } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    }
}

